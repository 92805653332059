@font-face {
  font-family: 'DINPro-Light';
  src: url('fonts/DINPro-Light.eot'); /* IE9 Compat Modes */
  src: url('fonts/DINPro-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/DINPro-Light.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/DINPro-Light.woff') format('woff'), /* Pretty Modern Browsers */
       url('fonts/DINPro-Light.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('fonts/DINPro-Light.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'DINPro-Regular';
  src: url('fonts/DINPro-Regular.eot'); /* IE9 Compat Modes */
  src: url('fonts/DINPro-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/DINPro-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/DINPro-Regular.woff') format('woff'), /* Pretty Modern Browsers */
       url('fonts/DINPro-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('fonts/DINPro-Regular.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'DINPro-Medium';
  src: url('fonts/DINPro-Medium.eot'); /* IE9 Compat Modes */
  src: url('fonts/DINPro-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/DINPro-Medium.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/DINPro-Medium.woff') format('woff'), /* Pretty Modern Browsers */
       url('fonts/DINPro-Medium.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('fonts/DINPro-Medium.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'DINPro-Bold';
  src: url('fonts/DINPro-Bold.eot'); /* IE9 Compat Modes */
  src: url('fonts/DINPro-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/DINPro-Bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/DINPro-Bold.woff') format('woff'), /* Pretty Modern Browsers */
       url('fonts/DINPro-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('fonts/DINPro-Bold.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('fonts/Montserrat-Regular.eot'); /* IE9 Compat Modes */
  src: url('fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/Montserrat-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/Montserrat-Regular.woff') format('woff'), /* Pretty Modern Browsers */
       url('fonts/Montserrat-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('fonts/Montserrat-Regular.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('fonts/Montserrat-SemiBold.eot'); /* IE9 Compat Modes */
  src: url('fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/Montserrat-SemiBold.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/Montserrat-SemiBold.woff') format('woff'), /* Pretty Modern Browsers */
       url('fonts/Montserrat-SemiBold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('fonts/Montserrat-SemiBold.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('fonts/Montserrat-Bold.eot'); /* IE9 Compat Modes */
  src: url('fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/Montserrat-Bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/Montserrat-Bold.woff') format('woff'), /* Pretty Modern Browsers */
       url('fonts/Montserrat-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('fonts/Montserrat-Bold.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Montserrat-Light';
  src: url('fonts/Montserrat-Light.eot');/* IE9 Compat Modes */
  src: url('fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/Montserrat-Light.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/Montserrat-Light.woff') format('woff'), /* Pretty Modern Browsers */
    url('fonts/Montserrat-Light.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/Montserrat-Light.svg#svgFontName') format('svg');/* Legacy iOS */
}

@font-face {
  font-family: 'MagdaClean-Regular';
  src: url('fonts/MagdaClean-Regular.eot'); /* IE9 Compat Modes */
  src: url('fonts/MagdaClean-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/MagdaClean-Regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/MagdaClean-Regular.woff') format('woff'), /* Pretty Modern Browsers */
    url('fonts/MagdaClean-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/MagdaClean-Regular.svg#svgFontName') format('svg'); /* Legacy iOS */
}
